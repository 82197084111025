<template>
  <div class="dashboard">
    <NavigationBar title="选择发布板块"/>

    <div class="content-wrapper">
      <div class="statistics-swipe-container">
        <van-swipe
            class="statistics-swipe"
            :autoplay="3000"
            indicator-color="white"
            vertical
        >
          <van-swipe-item>
            <div class="swipe-item">
              <span>当前已有</span>
              <van-rolling-text :start-num="0" :target-num="65" direction="down" :duration="0.3"/>
              <span>位用户发布讯息</span>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="swipe-item">
              <span>当前已有</span>
              <van-rolling-text :start-num="0" :target-num="215" direction="down" :duration="0.3"/>
              <span>位注册用户</span>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="container">

<!--        <PublishRule class="PublishRule"></PublishRule>-->

        <div class="content">
          <div class="menu-column">
            <div v-for="(item, index) in postCategories.slice(0, 4)" :key="index" class="menu-item"
                 @click="handleItemClick(item)">
              <img :src="item.icon" class="item-icon"/>
              <div class="item-content">
                <p class="item-title">{{ item.name }}</p>
                <p v-if="item.stats" class="item-description">当前已有 {{ item.stats.posts }} 帖子，{{ item.stats.subscribers }}
                  位用户订阅新帖</p>
                <p v-else class="item-description">{{ item.description }}</p>
              </div>
              <van-icon name="arrow" class="item-icon-arrow"/>
            </div>
          </div>

          <div class="mini-menu-grid">
            <div v-for="(item, index) in postCategories.slice(4)" :key="index" class="mini-menu-item"
                 @click="handleItemClick(item)">
              <img :src="item.icon" class="mini-item-icon"/>
              <p class="mini-item-title">{{ item.name }}</p>
            </div>
          </div>

        </div>

        <div class="close-button" @click="goBack">
          <van-icon name="cross" class="close-icon"/>
        </div>
      </div>
    </div>

    <!-- 子类别弹出窗口 -->
    <van-popup v-model:show="showSubcategories" position="bottom"  round>
      <div class="subcategory-list">
        <div v-for="(subcat, index) in currentSubcategories" :key="index" class="subcategory-item" @click="selectSubcategory(subcat)">
          {{ subcat.name }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { postCategories } from '@/js/postCategories';
import NavigationBar from "@/components/views/NavigationBar";
import PublishRule from "@/components/home/PublishRule";
import router from "@/router";

export default {
  components: {
    PublishRule,
    NavigationBar,
  },
  setup() {
    const router = useRouter();
    const showSubcategories = ref(false);
    const currentSubcategories = ref([]);
    const currentCategory = ref(null);

    const navigateToPostCreation = (category, subcategory = null) => {
      const basePath = subcategory ? subcategory.basePath : category.basePath;
      const name = subcategory ? subcategory.name : category.name;

      router.push({
        name: 'PostWrapperComponent',
        params: {
          basePath: basePath,
          postId: 0,
        },
        query: {
          mode: 'create',
        },
      });

    //   router.push({
    //     name: 'PostWrapperComponent',
    //     params: {
    //       basePath: basePath,
    //       postId:0,
    //     },
    //     query: {
    //       mode: 'create',               // Query 参数，设置为编辑模式
    //     },
    //   });
    };

    const handleItemClick = (item) => {
      if (item.subcategories && item.subcategories.length > 0) {
        currentSubcategories.value = item.subcategories;
        currentCategory.value = item;
        showSubcategories.value = true;
      } else {
        navigateToPostCreation(item);
      }
    };

    const selectSubcategory = (subcategory) => {
      showSubcategories.value = false;
      navigateToPostCreation(currentCategory.value, subcategory);
    };

    const goBack = () => {
      router.go(-1);
    };

    return {
      postCategories,
      handleItemClick,
      selectSubcategory,
      goBack,
      showSubcategories,
      currentSubcategories,
    };
  },
};
</script>

<style lang="less" scoped>
.dashboard {

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex-grow: 1;
  overflow-y: auto;
}

.statistics-swipe-container {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statistics-swipe {
  height: 40px;
}

.swipe-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;

  span {
    margin: 0 4px;
  }

  .van-rolling-text {
    font-size: 16px;
    font-weight: bold;
    color: #ff5722;
  }
}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  position: relative;
}

.content {
  width: 100%;
  padding: 0.2rem 0.3rem ;
}

.menu-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: 0.3rem;
  margin-bottom: 0.8rem;
}

.menu-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.item-icon {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.4rem;
  margin-right: 0.5rem;
}

.item-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
  color: #333;
}

.item-description {
  font-size: 0.75rem;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-icon-arrow {
  color: #ccc;
  font-size: 1rem;
}

.mini-menu-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
  width: 100%;
}

.mini-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.mini-item-icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 0.3rem;
}

.mini-item-title {
  font-size: 0.8rem;
  text-align: center;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.close-button {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideUp 0.5s ease-out;
  z-index: 1000;
  width: 10vw;
  height: 10vw;
  max-height: 60px;
  max-width: 60px;
}

.close-icon {
  width: 100%;
  height: 100%;

  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &::before {
    font-size: 2vw;
    color: #333;
    max-font-size: 4vh;
  }

  &:hover {
    background-color: rgb(204, 90, 90);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-font-size: 20vh;
  }
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

// 子类别弹出窗口样式
.subcategory-list {
  padding: 16px;
}

.subcategory-item {
  padding: 12px;
  font-size: 16px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
  &:active {
    background-color: #f5f5f5;
  }
}
</style>